import React from 'react';
import { Router } from '@reach/router';
// import { useBeforeUnload } from 'react-use';
import {
  CheckIn,
  MembershipRegister,
  FreeItemRegister,
} from '../components/routes';
import MembershipCancel from '../components/routes/Memberships/MembershipCancel';

const MembershipPage = () => {
  // useBeforeUnload('Enable', 'You have unsaved changes, are you sure?');
  return (
    <Router basepath="membership">
      <CheckIn path=":id/check-in" />
      <MembershipRegister path=":id/register" />
      <MembershipRegister path=":id/register/:membershipId" />
      <FreeItemRegister path=":id/perks" />
      <MembershipCancel path=":id/cancel" />
    </Router>
  );
};

export default MembershipPage;
