import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core';

import { Skeleton } from '@material-ui/lab';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { TextField } from 'formik-material-ui';
import { makeStyles } from '@material-ui/core/styles';
import CustomInputField from '../../custom/CustomInputField';
import { CustomImgCard } from '../../custom';
import { BusinessHeader, BusinessFooter, Content } from '../../layouts';
import SEO from '../../seo';
import { alert, businesses, memberships, organizations } from '../../../state';
import ConfirmationDialog from '../../custom/ConfirmationDialog';
import './style.css';

const MembershipCancel = ({
  fetchBusiness,
  fetchOrganization,
  business,
  location,
  fetchOrganizations,
  ...nav
}) => {
  const { i18n, t } = useTranslation();
  const { id: routeId } = nav;
  const [cancelState, setCancelState] = useState(false);
  const [membershipCancelled, setMembershipCancelled] = useState(false);
  const dispatch = useDispatch();
  const membershipConfig = useSelector(memberships.selectors.selectConfigs);
  const useStyles = makeStyles(() => ({
    textColor: {
      '& *:not(.Mui-error):not(.sq-card-message-error):not(button):not(button span)': {
        color: membershipConfig.brandColors?.textColor,
      },
    },
  }));
  const classes = useStyles();
  const isLoading = useSelector(memberships.selectors.selectIsLoading);

  const openAlert = (payload) => dispatch(alert.actions.open(payload));

  React.useEffect(() => {
    dispatch(memberships.actions.getMembershipConfig({ id: routeId }));
  }, []);

  React.useEffect(() => {
    if (business && business.culture) {
      i18n.changeLanguage(business.culture);
    }
  }, [business]);

  const { businessName, logoUrl } = membershipConfig;
  return (
    <Box className={classes.textColor}>
      <SEO title={`Member Check-In | ${businessName}`} />
      <BusinessHeader
        logoUrl={logoUrl}
        merchantUrl=""
        merchantPintunaUrl=""
        bgcolor={membershipConfig.brandColors?.headerColor || '#eee'}
      />
      <Box
        bgcolor={membershipConfig.brandColors?.backgroundColor || '#eee'}
        style={{ minHeight: 'calc(100vh - 133px)' }}
        id="membership-cancel"
      >
        <Container maxWidth="lg">
          <Content md={7} sm={10}>
            <Box
              borderRadius={20}
              border="1px solid #ccc"
              p={4}
              mb={4}
              bgcolor={membershipConfig.brandColors?.formColor || '#fff'}
            >
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Grid
                  container
                  item
                  xs={12}
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item>
                    <Box pt={2} display="block" align="center">
                      <Typography variant="h5" color="primary">
                        {isLoading ? (
                          <Skeleton width="190px" />
                        ) : (
                          membershipConfig.businessName
                        )}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid item>
                      <Box py={2}>
                        <CustomImgCard
                          isLoading={isLoading}
                          name={membershipConfig.businessName}
                          width="270px"
                          imageUrl={membershipConfig.imageUrl}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box py={2}>
                        <Formik
                          enableReinitialize
                          validationSchema={() =>
                            Yup.object().shape({
                              code: Yup.string().required(
                                'Membership number is required'
                              ),
                            })
                          }
                          initialValues={{
                            code: '',
                          }}
                          onSubmit={async ({ code }) => {
                            const payload = {
                              data: {
                                code,
                                membershipConfigId: membershipConfig.id,
                              },
                            };
                            const res = await dispatch(
                              memberships.actions.cancelRequest(payload)
                            );
                            if (!res.error) {
                              openAlert({
                                message: t('membership.successSentCode'),
                                severity: 'success',
                              });
                              setCancelState(true);
                            } else {
                              openAlert({
                                message:
                                  res?.payload?.message ||
                                  'Membership is not found',
                                severity: 'error',
                              });
                            }
                          }}
                        >
                          {({ isSubmitting, handleSubmit, values }) => {
                            const cancelConfirm = async () => {
                              const res = await dispatch(
                                memberships.actions.cancelMembership({
                                  cancelCode: values.verificationCode,
                                  code: values.code,
                                })
                              );
                              if (!res.error) {
                                setMembershipCancelled(true);
                              } else {
                                openAlert({
                                  message:
                                    res?.error?.message ||
                                    'Membership is not found',
                                  severity: 'error',
                                });
                              }
                            };
                            return (
                              <>
                                <Box
                                  style={{
                                    display: cancelState ? 'none' : 'block',
                                  }}
                                >
                                  <Typography
                                    display="block"
                                    align="center"
                                    variant="h5"
                                  >
                                    {t('membership.membershipCancellationForm')}
                                  </Typography>
                                  <Form>
                                    <Box mt={3}>
                                      <Grid
                                        container
                                        justifyContent="space-between"
                                        spacing={3}
                                        xs={10}
                                        style={{
                                          margin: 'auto',
                                          position: 'relative',
                                        }}
                                      >
                                        <Grid
                                          item
                                          xs
                                          style={{ marginRight: 100 }}
                                        >
                                          <CustomInputField
                                            customInput={Field}
                                            component={TextField}
                                            fullWidth
                                            autoFocus
                                            name="code"
                                            variant="outlined"
                                            label={t(
                                              'membership.enterYourMembershipNumber'
                                            )}
                                          />
                                        </Grid>
                                        <Grid
                                          item
                                          style={{
                                            position: 'absolute',
                                            right: 0,
                                            top: 5,
                                          }}
                                        >
                                          <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={handleSubmit}
                                            type="submit"
                                            disabled={isSubmitting}
                                          >
                                            {isSubmitting ? (
                                              <CircularProgress size={24} />
                                            ) : (
                                              t('verify')
                                            )}
                                          </Button>
                                        </Grid>
                                      </Grid>
                                    </Box>
                                    <Box>
                                      <Grid
                                        container
                                        justifyContent="space-between"
                                        spacing={3}
                                        xs={10}
                                        style={{ margin: 'auto' }}
                                      >
                                        <Grid
                                          item
                                          xs={12}
                                          style={{
                                            textAlign: 'justify',
                                            fontStyle: 'italic',
                                            marginTop: -10,
                                          }}
                                        >
                                          {t('membership.cancelNote')}
                                        </Grid>
                                      </Grid>
                                    </Box>
                                  </Form>
                                </Box>
                                <Grid
                                  container
                                  justifyContent="center"
                                  style={{
                                    display: !cancelState ? 'none' : 'flex',
                                  }}
                                >
                                  <Grid item xs={8}>
                                    <Box textAlign="center">
                                      <p>
                                        {t(
                                          'membership.pleaseEnterVerificationCode'
                                        )}
                                        <br />({t('membership.checkSpamFolder')}
                                        )
                                      </p>
                                      <CustomInputField
                                        customInput={Field}
                                        component={TextField}
                                        autoFocus
                                        fullWidth
                                        name="verificationCode"
                                        label={t(
                                          'membership.enterVerificationCode'
                                        )}
                                        variant="outlined"
                                      />
                                      <Box align="right" pb={2}>
                                        <Typography
                                          variant="caption"
                                          color="textSecondary"
                                          onClick={handleSubmit}
                                          style={{
                                            textDecoration: 'underline',
                                            cursor: 'pointer',
                                          }}
                                        >
                                          {`${t('resendCode')}?`}
                                        </Typography>
                                      </Box>
                                      <Box mt={3}>
                                        <Button
                                          variant="contained"
                                          color="primary"
                                          onClick={cancelConfirm}
                                          type="button"
                                          fullWidth
                                        >
                                          {t('membership.cancelMembership')}
                                        </Button>
                                      </Box>
                                    </Box>
                                  </Grid>
                                </Grid>
                              </>
                            );
                          }}
                        </Formik>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Content>
        </Container>
      </Box>
      <ConfirmationDialog
        open={membershipCancelled}
        setOpen={setMembershipCancelled}
        isConfirmation={false}
        title="Cancelled!"
        content={t('membership.membershipCancelled')}
        actionOk={() => {
          setTimeout(() => window.location.reload(), 100);
        }}
      />
      <Divider />
      <BusinessFooter bgcolor={membershipConfig.brandColors?.footerColor} />
    </Box>
  );
};

MembershipCancel.propTypes = {
  fetchBusiness: PropTypes.func.isRequired,
  fetchOrganization: PropTypes.func.isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  fetchOrganizations: PropTypes.func.isRequired,
  business: PropTypes.shape({
    name: PropTypes.string,
    logoUrl: PropTypes.string,
    website: PropTypes.string,
    culture: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  business: businesses.selectors.selectBusiness(state),
});

const mapDispatchToProps = (dispatch) => ({
  fetchBusiness: (businessId) =>
    dispatch(businesses.actions.fetchBusiness(businessId)),
  fetchOrganization: (orgId) =>
    dispatch(organizations.actions.fetchOrganization(orgId)),
  fetchOrganizations: () =>
    dispatch(organizations.actions.fetchOrganizations()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MembershipCancel);
